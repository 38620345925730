<template>
  <div class="question-form mt-4">
    <!-- FORM -->
    <form
      v-if="!submitted"
      class="card p-4"
      @submit.prevent="submitQuestion"
    >
      <h4 class="mb-3">
        Question Form
      </h4>
      <div class="form-group">
        <input
          id="question"
          v-model="formFields.question"
          type="text"
          class="form-control"
          placeholder="Enter your question here"
          required
        >
      </div>
      <div class="form-group">
        <textarea
          id="body"
          v-model="formFields.body"
          class="form-control"
          placeholder="Enter any additional clarrification and questions as needed."
          rows="4"
        />
      </div>

      <div class="form-group">
        <label for="program">Forum</label>
        <select
          id="program"
          v-model="formFields.program"
          class="form-control"
          required
        >
          <option
            v-for="program in programs"
            :key="program.id"
            :value="program.id"
            :disabled="!program.purchased"
          >
            {{ program.title }}
          </option>
        </select>
      </div>

      <b-button
        type="submit"
        class="btn btn-primary"
        :disabled="submitting || submitted"
      >
        {{ submitting ? 'Submitting' : 'Submit' }}
      </b-button>
    </form>

    <!-- SUCCESS -->
    <div v-if="submitted && success">
      <b-alert
        show
        variant="success"
      >
        Your question has been posted and will be reviewed by the admins.
        If deemed appropriate, you will be notified and your question
        will be posted to the forum.
      </b-alert>
    </div>

    <!-- FAILURE -->
    <div v-if="submitted && !success">
      <b-alert
        show
        variant="danger"
      >
        Failed to post question. Please test your connection and try again.
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionForm',
  data() {
    return {
      submitting: false,
      submitted: false,
      success: false,
      formFields: {
        program: null,
        question: '',
        body: '',
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    programs() {
      return this.$store.state.programs;
    },
  },
  methods: {
    async submitQuestion() {
      this.submitting = true;

      const url = `${process.env.VUE_APP_API_URL}questions/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
            body: JSON.stringify(this.formFields),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty


      this.submitting = false;
      this.submitted = true;

      if (response && response.status === 201) {
        this.success = true;
      } else {
        this.success = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  width: 100%;
}
</style>
