<template>
  <div class="ask-a-coach question content">
    <h1>Forum</h1>

    <!-- SEARCH -->
    <div class="d-flex">
      <div class="input-group">
        <input
          id="search"
          v-model="search"
          type="text"
          class="form-control"
          placeholder="Search questions and answers"
          @keydown.enter="fetchQuestions"
        >
        <div class="input-group-append">
          <div class="input-group-text">
            S
          </div>
        </div>
      </div>

      <b-button
        v-b-toggle.question-form
        class="btn btn-primary ml-3 text-nowrap px-3"
        size="sm"
      >
        Ask A Coach
      </b-button>
    </div>

    <!-- QUESTION FORM -->
    <b-collapse id="question-form">
      <question-form />
    </b-collapse>

    <!-- NEW QUESTIONS -->
    <div
      v-if="questions.length"
      class="mt-5"
    >
      <b-list-group>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center bg-light p-3 rounded-top font-weight-bold"
        >
          Forum
        </b-list-group-item>
        <b-list-group-item
          v-for="question in questions"
          :key="question.id"
          v-b-toggle="`collapse-question-${question.id}`"
          :class="{
            'shadow-lg my-3': question.user && question.user.id === userData.id,
          }"
        >
          <div
            class="d-flex justify-content-between align-items-center"
          >
            <div class="font-weight-bolder">
              {{ question.question }}
            </div>
            <div
              v-if="question.answers.length"
              class="background-gradient font-weight-bold py-1 px-3 text-white rounded-pill"
            >
              ANSWERED
            </div>
          </div>

          <b-collapse :id="`collapse-question-${question.id}`">
            <div class="mb-3 mt-4">
              {{ question.body }}
            </div>
            <div
              v-if="question.user"
              class="d-flex ml-4 mt-4 mb-3"
            >
              <b-img
                :src="question.user.profile_picture ? question.user.profile_picture : require('../assets/avatar-default.png')"
                rounded="circle"
                width="50"
                height="50"
                alt
              />
              <div
                class="ml-3 mt-2"
                style="line-height: 1.1;"
              >
                <p class="mb-0 font-weight-bolder">
                  {{ question.user.first_name }} {{ question.user.last_name }}
                </p>
                <small class="text-muted">{{ dateFromNow(question.created) }}</small>
              </div>
            </div>
            <div
              v-else
              class="d-flex"
            >
              <b-img
                :src="require('../assets/avatar-default.png')"
                rounded="circle"
                width="50"
                height="50"
                alt
              />
              <div
                class="ml-3 mt-2"
                style="line-height: 1.1;"
              >
                <p class="mb-0 font-weight-bolder">
                  [removed]
                </p>
                <small class="text-muted">{{ dateFromNow(question.created) }}</small>
              </div>
            </div>
            <div
              v-if="question.answers.length"
              class="mb-3"
            >
              <div
                v-for="answer in question.answers"
                :key="answer.id"
                class="mt-5"
              >
                <p class="pt-3 font-weight-bolder">
                  {{ answer.answer }}
                </p>
                <div
                  v-if="answer.coach"
                  class="d-flex ml-4"
                >
                  <b-img
                    :src="answer.coach.profile_picture ? answer.coach.profile_picture : require('../assets/avatar-default.png')"
                    rounded="circle"
                    width="50"
                    height="50"
                    alt
                  />
                  <div
                    class="ml-3 mt-2"
                    style="line-height: 1.1;"
                  >
                    <p class="mb-0 font-weight-bolder">
                      {{ answer.coach.first_name }} {{ answer.coach.last_name }}
                    </p>
                    <small class="text-muted">{{ dateFromNow(answer.created) }}</small>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex"
                >
                  <b-img
                    src="../assets/avatar-default.png"
                    rounded="circle"
                    width="50"
                    height="50"
                    alt
                  />
                  <div
                    class="ml-3 mt-2"
                    style="line-height: 1.1;"
                  >
                    <p class="mb-0 font-weight-bolder">
                      [removed]
                    </p>
                    <small class="text-muted">{{ dateFromNow(answer.created) }}</small>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- NO QUESTIONS -->
    <div
      v-if="loaded && !questions.length && !errors"
      class="mt-5"
    >
      No questions yet.
    </div>

    <!-- LOADING -->
    <div class="d-flex justify-content-center mt-5">
      <b-spinner
        v-if="loading"
        variant="info"
        style="width: 80px; height: 80px;"
      />
    </div>

    <!-- ERROR -->
    <div v-if="errors">
      <b-alert
        show
        variant="danger"
      >
        Unable to load questions. Please test your connection and refresh the page.
      </b-alert>
    </div>
  </div>
</template>

<script>
import QuestionForm from '@/components/QuestionForm.vue';

export default {
  name: 'AskACoach',
  components: {
    QuestionForm,
  },
  data() {
    return {
      questions: [],
      loaded: false,
      loading: false,
      errors: false,
      search: '',
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.fetchQuestions();
  },
  methods: {
    async fetchQuestions() {
      this.loading = true;

      let url = `${process.env.VUE_APP_API_URL}questions/`;

      if (this.search !== '') {
        url += `?search=${this.search}`;
      }

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;
      this.loaded = true;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.questions = responseJson;
      } else {
        this.errors = true;
      }
    },
    async removeUser(id) {
      const url = `${process.env.VUE_APP_API_URL}questions/${id}/remove_user/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 204) {
        this.fetchQuestions();
      } else if (response && response.status === 401) {
        alert('You do not have permission to delete this question.');
      } else {
        alert('Failed to delete. Please check your connection and try again.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  margin: 0 auto;
  max-width: 800px;
}

.responses {
  cursor: pointer;
}
</style>
